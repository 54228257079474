import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import { router, usePage } from '@inertiajs/react';
import Button from '@/components/atoms/button';
import Logo from '@/components/atoms/logo';
import Text from '@/components/atoms/text';
import Link from '@/components/atoms/link';
import Dropdown from '@/components/Dropdown';
import Icon from '@/components/atoms/icon';

const WebsiteHeader = ({ trans, isSticky, matches, auth, getQuoteModalOpen, client, policyNavItems, ...props }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [policiesSubmenuOpen, setPoliciesSubmenuOpen] = useState(false);
	const [publicLISubmenuOpen, setPublicLISubmenuOpen] = useState(false);
	const bodyElement = document.querySelector('body');
	const previousScrollY = useRef(0);
	const websiteBaseUrl = usePage()?.props?.websiteBaseUrl;

	useEffect(() => {
		if (menuOpen) {
			previousScrollY.current = window.scrollY;
			bodyElement.style.position = 'fixed';
			bodyElement.classList.add('overflow-hidden');
		} else {
			bodyElement.style.position = 'initial';
			bodyElement.classList.remove('overflow-hidden');
			setPoliciesSubmenuOpen(false);
			setPublicLISubmenuOpen(false);
			window.scrollTo(0, previousScrollY.current);
		}
	}, [menuOpen]);

	useEffect(() => {
		if (!matches) {
			setMenuOpen(false);
			setPoliciesSubmenuOpen(false);
			setPublicLISubmenuOpen(false);
		}
	}, [matches]);

	return (
		<header
			className={cn(
				'webite-header fixed z-10 flex flex-col-reverse justify-center w-full lg:relative lg:flex-row lg:pt-14',
				!trans && 'bg-white border-b border-formOutline',
				isSticky && 'lg:fixed lg:-top-eis-58 lg:border-b lg:border-formOutline lg:bg-white lg:transition-all'
			)}
		>
			<div className="flex flex-col w-full gap-12 mx-auto lg:px-14 xl:px-eis-72 max-w-eis-1440">
				<div
					className={cn(
						'flex flex-col items-center justify-between w-full lg:gap-12 lg:flex-row h-fit lg:h-eis-136',
						menuOpen && 'h-screen',
						isSticky && 'lg:h-eis-86 lg:transition-all'
					)}
				>
					<div className="w-full h-eis-65 lg:w-fit lg:flex lg:items-center lg:gap-8 xl:gap-12">
						<div
							className={cn(
								'flex items-center justify-between w-full px-6 py-3 bg-white border-b md:px-8 border-formOutline mobile-view-header lg:border-0 lg:z-0 lg:justify-center lg:px-0 lg:bg-transparent lg:w-auto lg:py-0'
							)}
						>
							<div className="flex items-center z-[3] logo">
								<Link isGeneralLink className="block" href={websiteBaseUrl}>
									<div className={cn('hidden transition-all shrink-0 w-eis-118 xl:w-eis-187', trans && 'lg:block', isSticky && 'lg:hidden')}>
										<Logo className="w-full h-auto" name="logo-big-white" />
									</div>
									<div className={cn('transition-all shrink-0 w-eis-106 3xs:w-eis-118 xl:w-eis-187', trans && 'lg:hidden', isSticky && 'lg:block')}>
										<Logo className="w-full h-auto" name="logo-big-colourful" />
									</div>
									<span className="sr-only">events insurance logo</span>
								</Link>
							</div>

							{matches && (
								<div className="flex items-center gap-3 lg:hidden">
									{auth?.user && (
										<Dropdown>
											<Dropdown.Trigger className="h-10">
												<Button variant="icon" size="icon" icon="user" className="w-auto p-0" iconClassName="w-6 h-6 shrink-0"></Button>
											</Dropdown.Trigger>

											<Dropdown.Content wrapClassName="!top-eis-38 !w-eis-217" align="center">
												<div className="p-1">
													<Dropdown.Link href="#">Go to my account</Dropdown.Link>
												</div>

												<div className="p-1 border-t border-eis-light-blue-1">
													<Dropdown.Link href={route('logout')} method="post" as="button">
														<Icon name="sign-out" className="text-formOutlineDark" />
														Sign Out
													</Dropdown.Link>
												</div>
											</Dropdown.Content>
										</Dropdown>
									)}

									<Button
										variant="primary"
										type="button"
										onClick={() => {
											getQuoteModalOpen();
										}}
									>
										Get a quote
									</Button>

									<Button
										variant="icon"
										size="icon"
										className="fflex flex-col items-center justify-center gap-1.5 lg:hidden z-[3] p-0"
										iconClassName="w-eis-26 h-eis-26 shrink-0"
										onClick={() => {
											setMenuOpen(!menuOpen);
										}}
									>
										<span className={cn('h-1 transition-all rounded-full bg-eis-black w-eis-26', menuOpen && '-rotate-45 translate-y-2.5')}></span>
										<span className={cn('h-1 transition-all rounded-full bg-eis-black w-eis-26', menuOpen && 'opacity-0')}></span>
										<span className={cn('h-1 transition-all rounded-full bg-eis-black w-eis-26', menuOpen && 'rotate-45 -translate-y-2.5')}></span>
										<span className="sr-only menu-text">{menuOpen ? 'Close' : 'Menu'}</span>
									</Button>
								</div>
							)}
						</div>

						<div
							className={cn(
								'w-full h-[calc(100vh-4.0625rem)] overflow-y-scroll transition-all translate-x-full bg-white lg:bg-transparent lg:transform-none mobile-view-menu-control lg:overflow-visible lg:h-auto navigation-container lg:w-fit overflow-x-hidden lg:overflow-x-visible',
								menuOpen && 'flex flex-col justify-between translate-x-0'
							)}
						>
							<div className={cn('flex flex-col lg:flex-row w-full lg:w-fit', isSticky && 'lg:mt-eis-85 lg:transition-all')}>
								<nav aria-label="Events Insusrance main navigation" className="w-full p-6 lg:p-0 lg:bg-transparent">
									<ul
										role="menubar"
										aria-label="header menu"
										className="flex flex-col justify-between gap-6 lg:justify-center lg:items-center lg:flex-row menu-list lg:gap-5 xl:gap-9"
									>
										<li
											className="menu-item lg:relative group"
											{...(!matches && {
												onMouseEnter: (e) => {
													e.preventDefault();
													setPoliciesSubmenuOpen(true);
												},
												onMouseLeave: (e) => {
													e.preventDefault();
													setPoliciesSubmenuOpen(false);
												},
											})}
										>
											<Link
												isExternal
												isGeneralLink
												href={`${websiteBaseUrl}/event-insurance-policies/`}
												className="flex flex-row items-center gap-1.5 transition-all lg:group-hover:text-corporateBlueBright relative after:absolute after:-bottom-6 after:left-0 after:content-[' '] after:w-full after:h-6 after:bg-transparent justify-between lg:justify-center pb-4 lg:pb-0 border-b border-formOutline lg:border-b-0 lg:text-white overflow-y-hidden lg:overflow-y-visible"
												{...(matches && {
													onClick: (e) => {
														e.preventDefault();
														setPoliciesSubmenuOpen(true);
													},
												})}
											>
												<Text
													type="p"
													variant="bold"
													className={cn('text-eis-black lg:group-hover:text-corporateBlueBright', trans && 'lg:text-white', isSticky && 'lg:text-eis-black')}
												>
													Policies
												</Text>
												<Icon
													name={matches ? 'chevron-right' : 'chevron-down'}
													className={cn(
														'text-eis-black lg:group-hover:text-corporateBlueBright',
														trans && 'lg:text-white',
														isSticky && 'lg:text-eis-black',
														!matches && 'w-4 h-4'
													)}
												/>
											</Link>
											<div
												className={cn(
													'fixed top-0 left-0 transition-all translate-x-full lg:top-full lg:-left-20 lg:absolute lg:pt-6 lg:w-[760px] z-[2] h-0 lg:h-auto overflow-y-scroll lg:overflow-visible hidden flex-col w-full',
													policiesSubmenuOpen && 'submenu-open'
												)}
											>
												<ul
													role="menu"
													aria-label="sub menu"
													className="relative flex flex-col lg:grid-cols-3 lg:gap-x-8 nav-dropdown lg:shadow-large-drop-shadow lg:transform-none lg:grid grow lg:grow-0"
												>
													<li role="none" className="lg:hidden">
														<Button
															variant="none"
															size="none"
															icon="chevron-left"
															className="flex flex-row items-center gap-2"
															textClassName="text-base font-bold text-eis-black"
															iconClassName="w-4 h-4 shrink-0"
															onClick={() => {
																setPoliciesSubmenuOpen(false);
															}}
														>
															Back
														</Button>
													</li>
													{policyNavItems.map((navItem, idx) => {
														const linker = client?.id ? `/quote/${navItem.uri}?client_id=${client.id}` : `/quote/${navItem.uri}`;

														return (
															<li className="menu-item blcok" role="none" key={`nav-item-${idx}`}>
																<Link isGeneralLink={!auth?.user} className="block lg:inline-block" href={auth?.user ? linker : navItem.externalUri}>
																	<Text
																		type="p"
																		variant="bold"
																		className={cn(
																			'flex w-full pb-4 transition-all border-b lg:pb-0 border-formOutline lg:border-b-0 text-eis-black lg:hover:text-corporateBlueBright lg:inline-block lg:text-14xs lg:leading-eis-1.29 lg:w-fit'
																		)}
																	>
																		{navItem.name}
																	</Text>
																</Link>
															</li>
														);
													})}
												</ul>
											</div>
										</li>
										<li
											className="menu-item lg:relative group"
											{...(!matches && {
												onMouseEnter: (e) => {
													e.preventDefault();
													setPublicLISubmenuOpen(true);
												},
												onMouseLeave: (e) => {
													e.preventDefault();
													setPublicLISubmenuOpen(false);
												},
											})}
										>
											<Link
												isExternal
												isGeneralLink
												href={`${websiteBaseUrl}/public-liability-insurance/`}
												className="flex flex-row items-center gap-1.5 transition-all lg:group-hover:text-corporateBlueBright relative after:absolute after:-bottom-6 after:left-0 after:content-[' '] after:w-full after:h-6 after:bg-transparent justify-between lg:justify-center pb-4 lg:pb-0 border-b border-formOutline lg:border-b-0 lg:text-white overflow-y-hidden lg:overflow-y-visible"
												{...(matches && {
													onClick: (e) => {
														e.preventDefault();
														setPublicLISubmenuOpen(true);
													},
												})}
											>
												<Text
													type="p"
													variant="bold"
													className={cn('text-eis-black lg:group-hover:text-corporateBlueBright', trans && 'lg:text-white', isSticky && 'lg:text-eis-black')}
												>
													Public Liability Insurance
												</Text>
												<Icon
													name={matches ? 'chevron-right' : 'chevron-down'}
													className={cn(
														'text-eis-black lg:group-hover:text-corporateBlueBright',
														trans && 'lg:text-white',
														isSticky && 'lg:text-eis-black',
														!matches && 'w-4 h-4'
													)}
												/>
											</Link>
											<div
												className={cn(
													'fixed top-0 left-0 transition-all translate-x-full lg:top-full lg:-left-20 lg:absolute lg:pt-6 lg:w-[760px] z-[2] h-0 lg:h-auto overflow-y-scroll lg:overflow-visible hidden flex-col w-full',
													publicLISubmenuOpen && 'submenu-open'
												)}
											>
												<ul
													role="menu"
													aria-label="sub menu"
													className="relative flex flex-col lg:grid-cols-2 lg:gap-x-8 nav-dropdown lg:shadow-large-drop-shadow lg:transform-none lg:grid grow lg:grow-0"
												>
													<li role="none" className="lg:hidden">
														<Button
															variant="none"
															size="none"
															icon="chevron-left"
															className="flex flex-row items-center gap-2"
															textClassName="text-base font-bold text-eis-black"
															iconClassName="w-4 h-4 shrink-0"
															onClick={() => {
																setPublicLISubmenuOpen(false);
															}}
														>
															Back
														</Button>
													</li>
													<li className="menu-item blcok" role="none">
														<Link isExternal isGeneralLink className="block lg:inline-block" href={`${websiteBaseUrl}/public-liability-insurance/`}>
															<Text
																type="p"
																variant="bold"
																className={cn(
																	'flex w-full pb-4 transition-all border-b lg:pb-0 border-formOutline lg:border-b-0 text-eis-black lg:hover:text-corporateBlueBright lg:inline-block lg:w-fit lg:text-14xs lg:leading-eis-1.29'
																)}
															>
																What Is Public Liability Insurance UK?
															</Text>
														</Link>
													</li>
													<li className="menu-item blcok" role="none">
														<Link
															isExternal
															isGeneralLink
															className="block lg:inline-block"
															href={`${websiteBaseUrl}/public-liability-insurance/who-needs-public-liability-insurance/`}
														>
															<Text
																type="p"
																variant="bold"
																className={cn(
																	'flex w-full pb-4 transition-all border-b lg:pb-0 border-formOutline lg:border-b-0 text-eis-black lg:hover:text-corporateBlueBright lg:inline-block lg:w-fit lg:text-14xs lg:leading-eis-1.29'
																)}
															>
																Who needs it?
															</Text>
														</Link>
													</li>
													<li className="menu-item blcok" role="none">
														<Link
															isExternal
															isGeneralLink
															className="block lg:inline-block"
															href={`${websiteBaseUrl}/public-liability-insurance/public-liability-insurance-cover/`}
														>
															<Text
																type="p"
																variant="bold"
																className={cn(
																	'flex w-full pb-4 transition-all border-b lg:pb-0 border-formOutline lg:border-b-0 text-eis-black lg:hover:text-corporateBlueBright lg:inline-block lg:w-fit lg:text-14xs lg:leading-eis-1.29'
																)}
															>
																What is covered?
															</Text>
														</Link>
													</li>
													<li className="menu-item blcok" role="none">
														<Link
															isExternal
															isGeneralLink
															className="block lg:inline-block"
															href={`${websiteBaseUrl}/public-liability-insurance-for-events/how-to-get-public-liability-insurance/`}
														>
															<Text
																type="p"
																variant="bold"
																className={cn(
																	'flex w-full pb-4 transition-all border-b lg:pb-0 border-formOutline lg:border-b-0 text-eis-black lg:hover:text-corporateBlueBright lg:inline-block lg:w-fit lg:text-14xs lg:leading-eis-1.29'
																)}
															>
																How to get cover
															</Text>
														</Link>
													</li>
													<li className="menu-item blcok" role="none">
														<Link
															isExternal
															isGeneralLink
															className="block lg:inline-block"
															href={`${websiteBaseUrl}/public-liability-insurance/how-much-is-public-liability-insurance/`}
														>
															<Text
																type="p"
																variant="bold"
																className={cn(
																	'flex w-full pb-4 transition-all border-b lg:pb-0 border-formOutline lg:border-b-0 text-eis-black lg:hover:text-corporateBlueBright lg:inline-block lg:w-fit lg:text-14xs lg:leading-eis-1.29'
																)}
															>
																What does it cost?
															</Text>
														</Link>
													</li>
												</ul>
											</div>
										</li>
										<li className="blcok">
											<Link isExternal isGeneralLink className="block" href={`${websiteBaseUrl}/about-us/`}>
												<Text
													type="p"
													variant="bold"
													className={cn(
														'flex w-full pb-4 transition-all border-b lg:pb-0 border-formOutline lg:border-b-0 text-eis-black lg:hover:text-corporateBlueBright lg:inline-block lg:w-fit',
														trans && 'lg:text-white',
														isSticky && 'lg:text-eis-black'
													)}
												>
													About us
												</Text>
											</Link>
										</li>
										<li className="blcok">
											<Link isExternal isGeneralLink className="block" href={`${websiteBaseUrl}/document-centre/`}>
												<Text
													type="p"
													variant="bold"
													className={cn(
														'flex w-full pb-4 transition-all border-b lg:pb-0 border-formOutline lg:border-b-0 text-eis-black lg:hover:text-corporateBlueBright lg:inline-block lg:w-fit',
														trans && 'lg:text-white',
														isSticky && 'lg:text-eis-black'
													)}
												>
													Documents
												</Text>
											</Link>
										</li>
										<li className="blcok">
											<Link isExternal isGeneralLink className="block" href={`${websiteBaseUrl}/blog/`}>
												<Text
													type="p"
													variant="bold"
													className={cn(
														'flex w-full pb-4 transition-all border-b lg:pb-0 border-formOutline lg:border-b-0 text-eis-black lg:hover:text-corporateBlueBright lg:inline-block lg:w-fit',
														trans && 'lg:text-white',
														isSticky && 'lg:text-eis-black'
													)}
												>
													Blog
												</Text>
											</Link>
										</li>
									</ul>
								</nav>

								{auth?.user ? (
									<div className="flex flex-col gap-6 px-6 pb-8 lg:hidden">
										<Button variant="secondary" type="button">
											My account
										</Button>
										<Link href={route('logout')} method="post" as="button" variant="dark" className="block">
											Sign Out
										</Link>
									</div>
								) : (
									<div className="flex flex-col gap-6 px-6 pb-8 lg:hidden">
										<Button
											onClick={() => {
												router.visit(route('login'));
											}}
											variant="secondary"
											type="button"
										>
											Sign in
										</Button>
									</div>
								)}
							</div>

							<div className={cn('z-10 w-full lg:z-[100] lg:absolute lg:top-0 lg:left-0 bg-corporateBlue', isSticky && 'lg:hidden')}>
								<div className="flex flex-col justify-between w-full gap-6 px-6 py-8 mx-auto lg:gap-0 lg:flex-row lg:px-14 xl:px-eis-72 max-w-eis-1440 lg:py-0">
									<Link
										isGeneralLink
										href="tel:+441425484860"
										className="flex flex-row items-center gap-3 transition-all border-b-0 border-white lg:border-transparent lg:px-4 lg:pt-4 lg:pb-eis-13 lg:border-b-3 lg:hover:border-corporateBlueBright lg:hover:bg-eis-dark-blue"
									>
										<Icon name="phone" className="text-white lg:text-corporateBlueBright" />
										<Text type="p" variant="bold" className="text-white">
											01425 470360
										</Text>
									</Link>
									<div className="flex gap-2">
										<div className="flex flex-col w-full gap-6 lg:w-fit lg:flex-row lg:gap-0 shrink-0">
											<Link
												isExternal
												isGeneralLink
												href={`${websiteBaseUrl}/about-us/claims-handling/`}
												className="flex flex-row w-full pb-4 transition-all border-b border-white lg:border-transparent lg:w-fit lg:px-4 lg:pt-4 lg:pb-eis-13 lg:border-b-3 lg:hover:border-corporateBlueBright lg:hover:bg-eis-dark-blue"
											>
												<Text type="p" variant="bold" className="text-white">
													Claims
												</Text>
											</Link>
											<Link
												isExternal
												isGeneralLink
												href={`${websiteBaseUrl}/contact-us/`}
												className="flex flex-row w-full pb-4 transition-all border-b border-white lg:border-transparent lg:w-fit lg:px-4 lg:pt-4 lg:pb-eis-13 lg:border-b-3 lg:hover:border-corporateBlueBright lg:hover:bg-eis-dark-blue"
											>
												<Text type="p" variant="bold" className="text-white">
													Contact us
												</Text>
											</Link>
										</div>
										{auth?.user ? (
											<div className="relative hidden w-full group my-account lg:block">
												<Dropdown>
													<Dropdown.Trigger className="h-10">
														<Button
															variant="none"
															size="none"
															icon="user"
															className="flex w-full gap-3 px-4 pt-4 transition-all border-transparent rounded-none pb-eis-13 border-b-3 group-hover:border-corporateBlueBright group-hover:bg-eis-dark-blue"
															iconClassName="text-corporateBlueBright w-6 h-6 shrink-0"
														>
															<Text type="p" variant="bold" className="text-white">
																My account
															</Text>
														</Button>
													</Dropdown.Trigger>

													<Dropdown.Content wrapClassName="!top-eis-38 !w-eis-217" align="center">
														<div className="p-1">
															<Dropdown.Link href="#">Go to my account</Dropdown.Link>
														</div>

														<div className="p-1 border-t border-eis-light-blue-1">
															<Dropdown.Link href={route('logout')} method="post" as="button">
																<Icon name="sign-out" className="text-formOutlineDark" />
																Sign Out
															</Dropdown.Link>
														</div>
													</Dropdown.Content>
												</Dropdown>
											</div>
										) : (
											<div className="relative flex-col items-center justify-center hidden w-full lg:flex">
												<Button
													onClick={() => {
														router.visit(route('login'));
													}}
													variant="secondary-white"
													type="button"
												>
													Sign in
												</Button>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>

					<Button
						variant={trans && !isSticky ? 'primary-white' : 'primary'}
						type="button"
						className="hidden lg:inline-flex shrink-0"
						onClick={() => {
							getQuoteModalOpen();
						}}
					>
						Get a quote
					</Button>
				</div>
			</div>
		</header>
	);
};

export default WebsiteHeader;
