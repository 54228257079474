import React, { useCallback, useState, useEffect, useLayoutEffect, useRef, useMemo } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import Heading from '@/components/atoms/heading';
import Text from '@/components/atoms/text';
import WebsiteHeader from '@/components/molecules/websiteHeader';
import WebsiteFooter from '@/components/molecules/websiteFooter';
import BspokeUnderwritingImg from '@/images/bspoke-underwriting.png';
import { ReactComponent as BannerShapeDesktop } from '@/svg/banner-shape-desktop.svg';
import { ReactComponent as BannerShapeMobile } from '@/svg/banner-shape-mobile.svg';
import GetQuoteModal from '@/Portal/components/molecule/GetQuoteModal';
import { Head, usePage } from '@inertiajs/react';

const WebsiteAuthenticatedLayout = ({ showBanner, showInsurer, fullwidth, className, wrapClassName, children, auth, client, pageTitle = null }) => {
	const getQuoteRef = useRef(null);
	const [isSticky, setIsSticky] = useState(false);
	const matches = useMediaQuery('(max-width: 1024px)');

	const websiteBaseUrl = usePage()?.props?.websiteBaseUrl;

	const policyNavItems = useMemo(() => ([
		{
			uri: 'one-off-insurance',
			name: 'One Off',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/one-off-insurance/overview/`,
		},
		{
			uri: 'multi-event-insurance',
			name: 'Multiple Events',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/multi-events-insurance/overview/`,
		},
		{
			uri: 'band-dj-and-performers',
			name: 'Band, DJ &amp; Performers',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/band-dj-and-performers-insurance/overview/`,
		},
		{
			uri: 'celebrations-parties',
			name: 'Celebrations and partie',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/celebrations-and-parties-insurance/overview/`,
		},
		{
			uri: 'christmas-lights',
			name: 'Christmas lights',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/christmas-lights-insurance/overview/`,
		},
		{
			uri: 'shows-insurance',
			name: 'Christmas Shows',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/christmas-shows/overview/`,
		},
		{
			uri: 'conferences-insurance',
			name: 'Conferences',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/conferences-insurance/overview/`,
		},
		{
			uri: 'exhibitors',
			name: 'Exhibitors',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/exhibitors-insurance/overview/`,
		},
		{
			uri: 'festivals-insurance',
			name: 'Festivals',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/festivals-insurance/overview/`,
		},
		{
			uri: 'fireworks-insurance',
			name: 'Fireworks',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/fireworks-insurance/overview/`,
		},
		{
			uri: 'hole-in-one',
			name: 'Hole in one: Prize indemnity',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/hole-in-one-insurance/overview/`,
		},
		{
			uri: 'hot-food-single',
			name: 'Hot food (Single)',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/hot-food-insurance/overview/`,
		},
		{
			uri: 'hot-food-multi',
			name: 'Hot food (Multiple)',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/hot-food-insurance/overview/`,
		},
		{
			uri: 'roll-a-dice',
			name: 'Roll the dice: Prize indemnity',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/roll-a-dice-insurance/overview/`,
		},
		{
			uri: 'shows-insurance',
			name: 'Shows, fairs, and exhibitions',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/shows-fairs-exhibitions-insurance/overview/`,
		},
		{
			uri: 'sporting-2-insurance',
			name: 'Sporting insurance',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/sporting-insurance/overview/`,
		},
		{
			uri: 'stallholders-insurance',
			name: 'Stallholders',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/stallholders-insurance/overview/`,
		},
		{
			uri: 'street-party-insurance',
			name: 'Street parties',
			externalUri: `${websiteBaseUrl}/event-insurance-policies/street-party-insurance/overview/`,
		},
	]), [websiteBaseUrl]);

	const toggleSticky = useCallback(
		(queryMatches) => {
			const scrollPosY = window.pageYOffset || document.documentElement.scrollTop;

			if (queryMatches && isSticky) {
				setIsSticky(false);
			} else if (!queryMatches) {
				if (scrollPosY > 57 && !isSticky) {
					setIsSticky(true);
				} else if (scrollPosY <= 57 && isSticky) {
					setIsSticky(false);
				}
			}
		},
		[isSticky]
	);

	useEffect(() => {
		const handleScroll = () => {
			toggleSticky(matches);
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [toggleSticky, matches]);

	const getQuoteModalOpen = () => {
		getQuoteRef.current?.onOpen();
	};

	return (
		<>
			<Head title={pageTitle ?? 'My Account'} />
			<div className={cn('bg-white', className && className)}>
				<WebsiteHeader
					isSticky={isSticky}
					matches={matches}
					auth={auth}
					trans={showBanner}
					getQuoteModalOpen={getQuoteModalOpen}
					client={client}
					policyNavItems={policyNavItems}
				/>

				<div
					className={cn(
						'flex flex-col w-full mx-auto relative',
						!fullwidth && 'px-6 md:px-10 lg:px-14 xl:px-eis-72 max-w-eis-1440',
						isSticky && 'pt-eis-194',
						showBanner && 'lg:-mt-eis-136',
						wrapClassName && wrapClassName
					)}
				>
					{showBanner && (
						<div className="relative flex items-center w-full h-full bg-white bg-center bg-no-repeat bg-cover pt-eis-118 lg:pt-eis-166 pb-eis-103 bg-gradient-to-r from-corporateBlue to-corporateBlueBright min-h-eis-384 lg:min-h-eis-400">
							<div className="relative z-[1] w-full px-6 mx-auto md:px-10 lg:px-14 xl:px-eis-72 max-w-eis-1440">
								<div className="flex flex-col gap-6">
									<Heading type="h1" className="text-white">
										My Account
									</Heading>
									<Heading type="h4" className="text-white">
										Hello {auth.user.name}!
									</Heading>
								</div>
							</div>

							<div className="absolute bottom-0 left-0 z-0 w-full text-white">
								<BannerShapeDesktop className="hidden md:block" />
								<BannerShapeMobile className="md:hidden" />
							</div>
						</div>
					)}

					{children}
				</div>

				{showInsurer && (
					<section className="bg-white">
						<div className="flex flex-col w-full px-0 mx-auto md:flex-row md:px-10 lg:px-14 xl:px-eis-72 max-w-eis-1440">
							<div className="flex items-center justify-between w-full gap-6 px-6 py-6 md:py-8 md:pl-0 md:pr-8 md:w-1/2 md:justify-end md:gap-8">
								<Text type="p" variant="bold" className="w-fit text-eis-black">
									Underwritten by:
								</Text>
								<img src={BspokeUnderwritingImg} alt="insurance choice awards" className="w-eis-120" />
							</div>

							<div className="flex items-center w-full gap-6 px-6 py-6 border-t md:py-8 md:pl-8 md:pr-0 md:w-1/2 md:justify-end md:gap-8 md:border-t-0 md:border-l border-formOutline">
								<Text type="p" variant="normal" className="w-full max-w-xl text-eis-black">
									Bspoke Underwriting Limited policies are underwritten by Watford Insurance Company Europe Limited.
								</Text>
							</div>
						</div>
					</section>
				)}

				<WebsiteFooter auth={auth} getQuoteModalOpen={getQuoteModalOpen} client={client} policyNavItems={policyNavItems} />
			</div>
			<GetQuoteModal ref={getQuoteRef} />
		</>
	);
};

export default WebsiteAuthenticatedLayout;

WebsiteAuthenticatedLayout.propTypes = {
	fullwidth: PropTypes.bool,
	showInsurer: PropTypes.bool,
	pageTitle: PropTypes.string,
};

WebsiteAuthenticatedLayout.defaultProps = {
	fullwidth: false,
	showInsurer: false,
	pageTitle: null,
};
